<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { useNotificationStore } from '@/stores/notificationStore';
import { Application } from '@/types';
import { noderedInstances } from '@/assets/nodered.instances';

@Component({})
export default class NodeRedApp extends Vue {
  @Prop() private application!: Application;
  private loading = true;
  private notificationStore = useNotificationStore();
  private noderedInstances = noderedInstances;

  private mounted() {
    if (this.$refs?.frame) {
      (this.$refs.frame as HTMLElement).addEventListener('load', () => {
        this.loading = false;
      })
    }
  }

  private get domainName() {
    const domainId = this.application.details.instanceNumber;
    return this.noderedInstances[Number(domainId) - 1]?.domainName;
  }

  private get dashboardSubPath() {
    const subPath = this.application.details.dashboardSubPath;
    const append = subPath.trim().replace(/^\/|\/$/g, '');
    return append;
  }

  private get dashboardLoginPath() {
    return (process.env.VUE_APP_NR_LOGIN_PATH || 'ea4dac95-58e2-4c0a-a296-e25383519aee/login').trim().replace(/^\/|\/$/g, '');
  }

  private get nodeRedDashboardUrl() {
    return `https://${this.domainName}/${this.dashboardLoginPath}?token=${encodeURIComponent(localStorage.getItem('token') || '')}&dashpath=${encodeURIComponent(`dashboard/${this.dashboardSubPath}`)}`;
  }
}
</script>

<template>
  <div class="fill-height embed-container overflow-x-hidden">
    <iframe v-show="!loading" ref="frame" class="embed-container" width="100%" height="100%" style="border: none" :src="nodeRedDashboardUrl"></iframe>
  </div>
</template>

<style scoped>

</style>