export const noderedInstances: { id: number; name: string; domainName: string; }[] = [
  { id: 1, name: 'Low Code 1', domainName: process.env.VUE_APP_NODERED_NODE1_FQDN!, },
  { id: 2, name: 'Low Code 2', domainName: process.env.VUE_APP_NODERED_NODE2_FQDN!, },
  { id: 3, name: 'Low Code 3', domainName: process.env.VUE_APP_NODERED_NODE3_FQDN!, },
  { id: 4, name: 'Low Code 4', domainName: process.env.VUE_APP_NODERED_NODE4_FQDN!, },
  { id: 5, name: 'Low Code 5', domainName: process.env.VUE_APP_NODERED_NODE5_FQDN!, },
  { id: 6, name: 'Low Code 6', domainName: process.env.VUE_APP_NODERED_NODE6_FQDN!, },
  { id: 7, name: 'Low Code 7', domainName: process.env.VUE_APP_NODERED_NODE7_FQDN!, },
  { id: 8, name: 'Low Code 8', domainName: process.env.VUE_APP_NODERED_NODE8_FQDN!, },
  { id: 9, name: 'Low Code 9', domainName: process.env.VUE_APP_NODERED_NODE9_FQDN!, },
  { id: 10, name: 'Low Code 10', domainName: process.env.VUE_APP_NODERED_NODE10_FQDN!, },
]